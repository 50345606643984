<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card-title>
        <DefaultTitle>
          <span class="no-break" v-html="rulesDescription.title"></span>
        </DefaultTitle>
      </v-card-title>
      <v-card-text>
        <DefaultSubText >
          <span class="no-break" v-html="rulesDescription.description"></span>
        </DefaultSubText>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <DefaultButton @click.prevent="show = false" primary>Zamknij</DefaultButton>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value:{},
    rulesDescription:{},
  },
  components: {
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style>
  .no-break {
    word-break: keep-all; 
    overflow-wrap: normal;
  }
</style>